/*!
*
* Template: Hope-Ui - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for responsive Template.
*
*/

// Ant Design
@import "antd/lib/modal/style/index.css";
@import "antd/lib/notification/style/index.css";
@import "antd/lib/button/style/index.css";
@import "./custom/air-datepicker.css";

@import "./custom/helper/fonts";
@import "bootstrap/scss/bootstrap";

// Page
@import "./custom/pages/transactions";

// Variables
@import "./custom/variable";
@import "./custom/variables/index";

// Custom Mixin And Helper
@import "./custom/helper/mixins";

// // Extra Components and Utilities and plugins
@import "./custom/components/components";
@import "./custom/layout-style/index";
@import "./custom/helper/utilities";
@import "./custom/plugins/plugins";

@import "./custom/rtl/index";

@import "./custom/pages/auth/authentication";
@import "./custom/pages/kanban/kanban";
@import "./custom/pages/pricing/pricing";
@import "./custom/pages/ui-kit/ui-kit";

@import "./custom/dark/index";

.mr-3 {
    margin-right: 3%;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mt-3 {
    margin-top: 3rem;
    text-align: center;
}

.buttonTransaction {
    margin-top: 25px;
}

.nav-pills {
    .nav-link {
        cursor: pointer;
    }
}

.button--icon {
    border: 0;
    background-color: transparent;
    padding: 10px;

    img {
        width: 20px;
        height: 20px;
    }
}

.transaction_modal {
    display: flex !important;
    justify-content: center;
    align-items: center;

    .modal-dialog {
        width: 100%;
        max-width: 55%;
        overflow: hidden;
    }
}

.h100 {
    height: 100%;
}