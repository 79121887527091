.modal-footer {
    padding: $modal-inner-padding * .5;
}

.modal__button{
    width: 188px;
    height: 44px;
    font-size: 16px;
    line-height: 175%;
}

.modal-header{
    border-bottom: 0;
}

.modal-footer{
    border-top: 0;
    justify-content: center;
    .modal__button:first-child{
        color: #B99972;
        border: 1px solid #B99972;
        border-radius: 4px;
        background-color: transparent;
    }
}


.modal__number{
    font-size: 16px;
    line-height: 175%;
    color: #232D42;
    margin-bottom: 9px;
}


.modal-body p{
    font-size: 16px;
    line-height: 175%;
    text-align: center;
    color: #8A92A6;
}

.modal-title{
    margin-top: 30px;
}

.modal-footer{
    margin-bottom: 30px;
    margin-top: 25px;
}

.modal-body{
    padding: 0 107px;
}

.modal-title{
    text-align: center;
    margin: 25px auto 0 auto;
    width: 320px;
    font-weight: 600;
}
.modal-dialog {
    max-width: 600px;
    .btn-close{
        position: absolute;
        right: 20px;
        top: 20px;
    }
}