.file-drop-input {
    position: relative;
    width: 50%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.25rem;
    border: 1px dotted #ced4da;

    &__description{
        font-size: 12px;
        line-height: 110%;
        color: #8A92A6;
        position: absolute;
        left: 100%;
        width: 100px;
        top: 10px;
        display: none;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
    }

    &__plus {
        font-size: 1.5vw;
        color: $gold;
    }

    &__text {
        text-align: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 22px;
        color: #3A57E8;
        &--active {
            text-align: center;
            color: $gold;
        }
    }
    &__preloader {
        img {
            width: 35px;
        }
    }

    &__error {
        font-size: 12px;
        color: #f5222d;
        position: absolute;
        bottom: -20px;
        left: 10px;
        z-index: 1;
    }

    &__preview {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            height: 50%;
        }

        a {
            padding: 10px;
        }
    }
}

.step .file-drop-input__description{
    display: block;
}
