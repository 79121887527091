.pending-payments-customer-table {
    padding-top: 100px;
}


.table-date {
    width: 200px;
}

.table-name-lawyer {
    width: 180px;
}

.table-description {
    width: 180px;
}

.table-payment-title {
    width: 200px;
}

.table-payment-id {
    width: 220px;
}

.transaction-description-table {
    width: 280px;
    /* white-space: pre-wrap;
    word-break: break-word; */
}

.modal-closed-hash {
    display: flex;
    justify-content: space-between;
}

.modal-closed-hash-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-hash-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-closed-date {
    display: flex;
    justify-content: space-between;
}

.modal-closed-date-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-date-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-closed-case {
    display: flex;
    justify-content: space-between;
}

.modal-closed-case-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-case-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
}

.modal-closed-amount {
    display: flex;
    justify-content: space-between;
}

.modal-closed-amount-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-amount-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
}

.modal-closed-status {
    display: flex;
    justify-content: space-between;
}

.modal-closed-status-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-status-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
}

.modal-closed-transaction {
   text-align: center;
}

.modal-closed-transaction-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.modal-closed-transaction-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
}

.preloader-box-payments {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 200px;
}
