.profile-img {
    margin-top: -4.375rem !important;
    img {
        border: 4px solid $white;
    }
}
.comment-attagement {
    position: absolute;
    left: auto;
    right: 1.875rem;
    font-size: 1.25rem;
}

.status__button{
    font-size: 18px;
    width: 180px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.status-register{
    background-color: #ffead1;
    border: 1px solid #B99972;
}

.status-pending{
    background-color: #fff9d2;
    border: 1px solid #f7da0b;
}

.status-verify{
    background-color: rgb(190 255 164 / 60%);
    border: 1px solid #53AB30FF;
}

.verification-box {
     display: flex;
     justify-content: space-around;
     text-align: center;
}

.verification-box-image-title {
     margin-bottom: 15px;
}

.verification-box-document-title {
     margin-bottom: 15px;
}

.verification-box-date-title {
     margin-bottom: 15px;
}

.verification-box-image-value {
    padding: 0.375rem 0.375rem 0rem 0.375rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #f5f5f5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

.verification-box-document-value {
    padding: 0.375rem 4.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #f5f5f5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

.verification-box-date-value {
    padding: 0.375rem 4.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #f5f5f5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}