.log-step{
  list-style-type: none;
  position: relative;
  padding-left: 0;
  align-items: center;
  z-index: 2;
  li{
    width: 30px;
    border-radius: 30px;
    height: 30px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 175%;
    color: #FFFFFF;
    background: #B99972;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li.active{
    width: 51px;
    height: 51px;
    background: #B99972;
    font-size: 16px;
  }
  &:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #DFD0BF;
    top: 26px;
    left: 0;
    z-index: -1;
  }
}

.step__description{
  font-size: 14px;
  line-height: 175%;
  color: #8A92A6;
  margin: 10px 0;
}

.step__button{
  min-width: 188px;
  min-height: 44px;
}

.step__total{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-top: 20px;
  border-top:  1px solid #D1D1D1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.card-body .step__button-prev{
  min-width: 188px;
  min-height: 44px;
  margin-right: 25px;
  color: #B99972;
  border: 1px solid #B99972;
  background-color: transparent;
  &:hover{
    background-color: #B99972;
    color: #fff;
  }
}


.log-step  .active ~ li{
  background: #DFD0BF;
}


.btn-primary:disabled{
  background-color: #524636;
  border-color: #524636;
}