.search_box {
    padding-top: 75px;
    padding-bottom: 25px;
}

.search_title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.search_input_box {
    text-align: center;
}

.search_button {
    margin-top: 5px;
}

.ant-input:focus,
.ant-input-focused {
    border-color: #b99972;
    box-shadow: none;
    border-right-width: 1px !important;
    outline: 0;
}

.ant-input:hover {
    border-color: #b99972;
    border-right-width: 1px !important;
}
