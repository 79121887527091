.card-title {
    font-size: 16px;
    line-height: 175%;
    color: #232d42;
}
.card-input {
    width: 70%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-error {
    width: 70%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-number {
    width: 40%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-number-error {
    width: 40%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-number-img {
    width: 20%;
    margin-left: 10%;
}

.card-input-expiration {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-expiration-error {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-security {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-security-error {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-city {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-city-error {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-postcode {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-input-postcode-error {
    width: 90%;
    padding: 0.375rem 0.75rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: text;
}

.card-textarea {
    width: 100%;
    height: 200px;
    resize: none;
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

.card-textarea-error {
    width: 100%;
    height: 200px;
    resize: none;
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fc002e;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

.card-documents {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.card-documents-add {
    background-color: #b99972;
    box-shadow: 0 0.125rem 0.25rem rgb(185 153 114 / 30%);
    border: 0;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
}

.card-documents-close {
    cursor: pointer;
    background-color: transparent;
    color: #212529;
    border: none;
    font-size: 16px;
}

.card-documents-close-disabled {
    background-color: transparent;
    color: #21252952;
    border: none;
    font-size: 16px;
}

.card-upload {
    display: flex;
    justify-content: center;
}

.card-upload-box {
     display: flex;
     justify-content: space-between;
}

.card-upload-box-uploaded-documents {
    margin-top: 10px;
}

.card-upload-box-uploaded-documents-item {
    color: #008000;
}

.card-upload-box-uploaded-documents-item-error {
    color: #d20e0e;
}

.card-upload-box-button {
    margin-bottom: 1rem;
}

.card-upload-box-description {
    font-size: 12px;
    color: #232d42;
    margin-bottom: 0.2rem;
}

.card-upload-box-description-save {
    font-size: 12px;
    color: #008000;
    margin-bottom: 0.2rem;
}

.card-upload-box-description-error {
    font-size: 12px;
    color: #d20e0e;
    margin-bottom: 0.2rem;
}

.card-upload-box-inputs {
    width: 50%;
    text-align: center;
}

.card-upload-box-table {
     margin-top: 10px;
     text-align: center;
}

.card-upload-box-input {
    cursor: text;
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

.card-upload-box-input-message-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-input-message {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    visibility: hidden;
}

.card-upload-box-input-fullname-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-input-fullname {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: hidden;
}

.card-upload-box-input-billing-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-input-billing {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: hidden;
}

.card-upload-box-input-city-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-input-city {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    margin-bottom: 10px;
    visibility: hidden;
}



.card-upload-box-input-create-case-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    visibility: visible;
}

.card-upload-box-input-create-case {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -30px;
    visibility: hidden;
}

.card-upload-box-input-assign-customer-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: 0px;
    visibility: visible;
}

.card-upload-box-input-assign-customer {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: 0px;
    visibility: hidden;
}

.card-upload-box-textarea-message-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -35px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-textarea-message {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: -35px;
    margin-bottom: 10px;
    visibility: hidden;
}

.card-upload-box-input-document-description {
    cursor: text;
    padding: 0.175rem 0.50rem;
    font-size: 14px;
    font-weight: 400;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
}

 
.description-load-documents-table {
    width: 400px;
}

.documents-table-description {
    white-space: pre-wrap;
    word-break: break-word;
     
}

.errorInput {
    border: 1px solid red;
}

.table-remove {
    color: #212529;
    cursor: pointer;
}

.table-remove:hover {
    color: #d20e0e;
    transition: 0.2s;
}

.modal-pay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    animation-name: appear;
    animation-duration: 300ms;
}

.modal-dialog-pay {
    width: 100%;
    max-width: 550px;
    background: white;
    position: relative;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
}

.modal-header-pay,
.modal-footer-pay {
    display: flex;
    align-items: center;
    padding: 1rem;
}
.modal-header-pay {
    border-bottom: 1px solid #dbdbdb;
    justify-content: space-between;
}
.modal-footer-pay {
    border-top: 1px solid #dbdbdb;
    justify-content: flex-end;
}

.modal-footer-pay button {
    background-color: rgb(185, 153, 114);
    color: rgb(255, 255, 255);
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.modal-close-pay {
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}
.modal-body-pay {
    overflow: auto;
}
.modal-content-pay {
    padding: 1rem;
}

.modal-title-pay {
    margin: 0;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }
    to {
        transform: translateY(0);
    }
}

input:invalid {
    animation: shake 300ms;
    border: 1px solid red;
}

@keyframes shake {
    25% { transform: translate(4px); }
    50% { transform: translate(-4px); }
    75% { transform: translate(4px); }
}
