.service-block{
  border: 1px solid #BF986C;
  text-decoration: none;
  background-color: transparent;
  transition: all linear .19s;
  border-radius: 8px;
  position: relative;
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87);
    z-index: 2;
    transition: all linear .19s;
    text-align: center;
  }
  &__count{
    color: #B99972;
    border: 1px solid #B99972;
    width: 47px;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 6px;
    top: 20px;
    right: 20px;
    justify-content: center;
    z-index: 2;
  }
  .card-body{
    min-height: 115px;
  }
  i{
    z-index: 2;
  }
  svg{
    height: 45px;
    width: 45px;
    path{
      fill: #BF986C;
      transition: all linear .19s;
    }
  }
  &:before{
    content: "";
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 8px;
    transition: all linear .19s;
    background-image: linear-gradient(
                    115deg
            ,#e1c9a5,#bf986c 100%);
  }
  &:hover{
    .service-block__count {
      color: #fff;
      border: 1px solid #fff;
    }
    .service-block__title {
      color: rgba(255, 255, 255, 0.87);
    }
    svg path{
      fill: #fff;
    }
    &:before{
      opacity: 1;
    }
  }
}
.service__title{
  font-weight: bold;
  font-size: 60px;
  line-height: 73px;
  text-transform: uppercase;
  position: absolute;
  top: -120px;
  color: #fff;
}

.p-50{
  padding: 50px;
  padding-right: 80px;
}

//.service-container{
//  position: relative;
//  top: -30px;
//}

.mt-n5{
  position: relative;
  top: -30px;
}

.service-top{
  position: absolute;
  top: -160px;
  h6{
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #EFEFEF;
  }
  h1{
    margin-left: 26px;
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    color: #fff;
  }
}

.br-8{
  border-radius: 8px;
}

.service-page{
  .card{
    border: 0;
  }
}
.service-card{
  .card-text, li{
    font-size: 20px;
    line-height: 38px;
  }

  .btn-primary{
    width: 211px;
    height: 44px;
    font-size: 16px;
    border-radius: 4px;
    font-family: Roboto, sans-serif;
  }
  .card-title{
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 25px;
    &:before{
      position: absolute;
      content: '';
      width: 85px;
      height: 8px;
      bottom: -7px;
      left: 0;
      background-color: #BF986C;
    }
  }
  .card-title~.card-text{
    margin-bottom: 25px;
  }
  button{
    float: right;
  }
}

.card-input {
  background-color: 'red'
}

.card-text-preloader {
  width: 40px;
}