.calendar-wrapper {
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #ffffff;
}

.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1.5px;
    background-color: #f3eee8;
}

.cell-wrapper {
    min-width: 140px;
    min-height: 80px;
    background-color: #B99972;
    color: #ffffff;
}

.cell-wrapper-header {
    min-width: 140px;
    min-height: 24px;
    background-color: #B99972;
    color: #ffffff;
    border-bottom: 2px solid #f3eee8;    
}

.cell-wrapper-weekend {
    min-width: 140px;
    min-height: 80px;
    background-color: #b99972b3;
    color: #ffffff;
}

.row-in-cell {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.day-wrapper {
    height: 32px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
}

.show-day-wrapper {
    display: flex;
    justify-content: flex-end;
}

.current-day {
    height: 90%;
    width: 90%;
    background-color: rgb(255, 0, 0);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.other-month {
    color: rgba(50, 50, 50, 0.468);
}

.div-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #B99972;
    padding: 16px;
}

.text-wrapper-month {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    margin-right: 8px;
}

.text-wrapper-year {
    font-size: 18px;
    color: #ffffff;
}

.button-wrapper {
    border: unset;
    background-color: #ffffff;
    height: 30px;
    margin-right: 2px;
    border-radius: 4px;
    color: #B99972;
}
.button-wrapper-today {
    border: unset;
    background-color: #ffffff;
    height: 30px;
    margin-right: 2px;
    border-radius: 4px;
    color: #B99972;
    padding: 0px 16px;
    font-weight: 600;
}

.button-create-event {
    border: unset;
    background-color: #ffffff;
    height: 30px;
    margin-right: 30px;
    border-radius: 4px;
    color: #B99972;
    padding: 0px 16px;
    font-weight: 600;
}

.button-wrapper svg {
    margin-top: -5px;
}


.header-wrapper {
    background-color: #b99972b3;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.event-list-wrapper {
    margin: unset;
    list-style-position: inside;
    padding-left: 4px;
}

.event-item-wrapper-high {
    position: relative;
    left: -14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 114px;
    border: unset;
    border-radius: 5px;
    color: #000000de;
    font-weight: 500;
    background-color: #f93636;
    cursor: pointer;
    margin: 3px 0px;
    padding: 0px 3px;
}

.event-item-wrapper-medium {
    position: relative;
    left: -14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 114px;
    border: unset;
    border-radius: 5px;
    color: #000000de;
    font-weight: 500;
    background-color: #e4e432;
    cursor: pointer;
    margin: 3px 0px;
    padding: 0px 3px;
}

.event-item-wrapper-low {
    position: relative;
    left: -14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 114px;
    border: unset;
    border-radius: 5px;
    color: #000000de;
    font-weight: 500;
    background-color: #4bf72d;
    cursor: pointer;
    margin: 3px 0px;
    padding: 0px 3px;
}

.event-item-wrapper-done {
    position: relative;
    left: -14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 114px;
    border: unset;
    border-radius: 5px;
    color: #000000de;
    font-weight: 500;
    background-color: #959a95;
    cursor: pointer;
    margin: 3px 0px;
    padding: 0px 3px;
}

.create-event-calendar {
    border: 1px solid #B99972;
    box-shadow: none;
    color: red;
}

.create-event-calendar:hover {
    border: 1px solid #B99972;
    box-shadow: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #B99972;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #b99972b3;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border: 1px solid #B99972;
    border-radius: 2px;
    content: '';
}

.ant-picker-now-btn {
    color: #B99972;
    text-decoration: underline;
}

.card-upload-box-time-event-error {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    visibility: visible;
}

.card-upload-box-time-event {
    font-size: 12px;
    color: #f5222d;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    visibility: hidden;
}