@import "./simple-loder";

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 999;

  &-image {
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    z-index: -1;
    //background: url(/) no-repeat;
  }

  &--hide {
    transition: opacity 0.3s ease-in;
    opacity: 0;
  }

  &--zindex {
    z-index: 999;
    pointer-events: none;
  }

  img {
    width: max(11vw, 200px);
  }
}
