.sign-bg {
    position: absolute;
    left: 0;
    top: 0;

    &.sign-bg-right {
        left: auto;
        right: 0;
    }
}
.gradient-main {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.logo-sign {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.relative {
    position: relative;
}
:root {
    --swiper-theme-color: rgba(0, 0, 0, 0.9) !important;
}

.btn-primary {
    background-color: $gold;
    box-shadow: 0 0.125rem 0.25rem rgb(185 153 114 / 30%);
    border: 0;
    &:active {
        background-color: #9a661e;
        border-color: #b7874dff;
    }
    &:focus {
        background-color: #9a661e;
        border-color: #b7874dff;
        box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%),
            0 1px 1px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(185 153 114 / 50%);
    }
    &:hover {
        background-color: #b99972;
        box-shadow: 0 0.125rem 0.25rem rgb(185 153 114 / 30%),
            0 0.125rem 0.5rem rgb(185 153 114 / 35%);
    }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: #b7874d;
}
.form-check-input:checked {
    background-color: $gold;
    border-color: $gold;
}
.profile-tab {
    .nav-link {
        color: $gold;
    }
}

.login-content h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 77px;
    color: #ffffff;
    z-index: 1;
    position: absolute;
    top: 40%;
    left: 70px;
}

.login-bottom {
    margin-top: -12.5rem;
}

@include media-breakpoint-up(xxl) {
    .auth-card {
        padding: 0 4.25rem;
    }
}

@include media-breakpoint-down(sm) {
    .login-header {
        height: 12.5rem !important;
    }
    .login-bottom {
        margin-top: -6.25rem;
    }
}
