.main-content{
    .content-inner {
        min-height: calc(100vh - 44.6rem);
    }
}
.sidebar {
    &+.main-content {
        margin-left: 17.2rem;
        transition: $navbar-vertical-transition;
    }
}

@include media-breakpoint-up(xl) {
    .sidebar {
        &+.main-content {
            .nav {
                .navbar-brand{
                    display: none;
                }
            }
        }
    }
}

