input {
    cursor: pointer;
}

.pending-cases-title {
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    text-transform: uppercase;
    position: absolute;
    top: -170px;
    color: #fff;
}

.pending-cases-sort-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.calendar-box {
    display: flex;
}

.ant-input-affix-wrapper {
    border: 1px solid #ced4da;
    cursor: pointer;
}

.calendar-box-input:hover {
    border-color: #b99972 !important;
}

.calendar-box-input:focus {
    border-color: #b99972 !important;
    box-shadow: none;
}

.ant-input-affix-wrapper:hover {
    border-color: #b99972 !important;
}

.ant-input-affix-wrapper-focused {
    border-color: #b99972 !important;
    box-shadow: none;
}

.ant-btn-primary {
    background-color: #b99972 !important;
    border-color: #b99972 !important;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-link {
    color: #ff0000 !important;
}

.ant-table-filter-trigger.active svg {
    fill: #b99972 !important;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    color: #b99972 !important;
    background-color: #e6f7ff !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #b99972;
    border-color: #b99972;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #b99972 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #b99972 !important;
}

.ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #b99972 !important;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
}

.ant-table-thead > tr > th {
    background: #b999722a !important;
}

.ant-pagination-item-active {
    border-color: #b99972 !important;
}

.ant-pagination-item:hover {
    border-color: #b99972 !important;
}

.ant-pagination-next ant-pagination-disabled {
    border-color: #e6f7ff !important;
}

.ant-pagination-prev {
    fill: #b99972 !important;
}

.ant-pagination-next {
    fill: #b99972 !important;
}

.ant-pagination-jump-prev {
    fill: #b99972 !important;
}

.ant-pagination-jump-next svg {
    fill: #b99972 !important;
}

.ant-pagination-jump-prev svg {
    fill: #b99972 !important;
}

.anticon-left svg {
    fill: #b99972 !important;
}

.anticon-right svg {
    fill: #b99972 !important;
}

.ant-pagination-item-link:hover {
    border-color: #b99972 !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed;
}

.ant-select
    ant-pagination-options-size-changer
    ant-select-single
    ant-select-show-arrow {
    border-color: #b99972 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #b99972 !important;
    border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #b99972 !important;
    box-shadow: 0 0 0 2px #b9997233;
    border-right-width: 1px !important;
    outline: 0;
}

.ant-select-open {
    border-color: #b99972 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #b9997286;
}

.ant-btn-dangerous {
    color: #b99972 !important;
    border-color: #b99972 !important;
    background: #fff;
}

.header-pending-case-details {
    margin-top: 50px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

.header-pending-case-details-titles {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    padding-right: 30px;
}

.header-pending-case-details-titles-text {
    margin-left: -3%;
}

.documents-pending-case-details {
    margin-top: 25px;
    padding: 15px 0px;
    flex-direction: row !important;
    justify-content: space-around;
}

.documents-pending-case-details-description {
    margin-top: 25px;
    margin-bottom: 25px;
}

.documents-pending-case-details-description-title {
    text-align: left;
    margin-left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.documents-pending-case-details-description-text {
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.documents-pending-case-details-documents-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 25px;
    margin-bottom: 25px;
}

.documents-pending-case-details-button {
    margin-top: 35px;
    text-align: center;
}

.documents-pending-case-details-documents-text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.documents-pending-case-details-documents-box {
    display: flex;
    justify-content: space-between;
}

.documents-pending-case-details-documents-date {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.documents-table-pending-case-details-customer {
    width: 300px;
    /* white-space: pre-wrap;
    word-break: break-word; */
}

.table-number-history-message {
    width: 90px;
}

.table-sent-history-message {
    width: 90px;
}

.documents-table-pending-case-details-customer-short {
    width: 300px;
}

.documents-table-pending-case-details {
    /* white-space: pre-wrap;
    word-break: break-word; */
}

.table-description-history-message {
    /* width: 200px; */
}

.table-title-message-history-message {
    /* width: 160px; */
    /* white-space: pre-wrap;
    word-break: break-word; */
}

.table-date-history-message {
    width: 200px;
}

.table-name-customer-history-message {
    /* white-space: pre-wrap;
    word-break: break-word; */
    /* width: 200px; */
}
.table-button-history-message {
    width: 95px;
}

.table-attached-history-message {
    width: 90px;
}

.message-viewed {
    font-weight: 800;
}

.messages-pending-case-details {
    margin-top: 50px;
    padding: 10px;
}

.messages-pending-case-details-table-box {
    margin-top: 25px;
    margin-bottom: 25px;
}

.messages-pending-case-details-payment-arrears-box {
    margin-top: -35px;
    margin-bottom: 25px;
}

.messages-pending-case-details-table-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}

.messages-pending-case-details-payment-arrears-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.messages-pending-case-details-payment-arrears-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.messages-pending-case-details-payment-arrears-price {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5px;
    max-width: 400px;
}

.messages-pending-case-details-payment-arrears-description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 600px;
    text-align: center;
}

.messages-pending-case-details-payment-arrears-write {
    text-align: center;
}

.pending-case-archive-info-box {
    display: flex;
    justify-content: space-around;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.pending-case-archive-description{
    max-width: 40%;
}

.ant-modal-title {
    text-align: center;
}

.message-modal-pending-case-subtitle {
    display: flex;
    justify-content: space-around;
    border: 1px solid #d9d9d9;
    padding: 15px 0px;
}

.message-modal-pending-case-subtitle-box {
    text-align: center;
    margin-top: 10px;
}

.message-modal-pending-case-subtitle-text {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.message-modal-pending-case-subtitle-title {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.message-modal-pending-case-description {
    text-align: center;
    margin-top: 10px;
}

.send-message-description-table {
    width: 225px;
}

.message-modal-pending-case-description-title {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
}

.message-modal-pending-case-description-text {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.message-modal-pending-case-documents {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.message-modal-pending-case-date {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    bottom: -60px;
    left: 0;
}

.uploadDocumentsMessageInModalBox {
    display: flex;
    justify-content: space-around;
}

.invoice-pay-pending-case {
    width: 50%;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
}

.invoice-pay-pending-case-title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
}
.invoice-pay-pending-case-subtitle {
    text-align: left;
    margin-left: 90px;
}

.ant-table-tbody {
    padding: 5 !important;
}
.ant-table-tbody > tr > td {
    padding: 2 !important;
}
