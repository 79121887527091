.card {
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;
	border: 0!important;
    .card-header {
		margin-bottom: 0;
		border: 0;
		padding-bottom: 0;
		.header-title {
			.card-title {
				margin-bottom: 0;
			}
		}
	}
}
.card-header {
    .card-title {
        margin-bottom: 0;
    }
}
