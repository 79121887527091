/* LAWYER */

.selected-open-case-lawyer-title {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.87);
}

.open-case-lawyer-modal-header-box {
    display: flex;
    justify-content: space-around;
    border: 1px solid #d9d9d9;
    padding: 15px 0px;
}

.open-case-lawyer-modal-header-number-case {
    border-right: 1px solid #d9d9d9;
    padding-right: 30%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.open-case-lawyer-modal-header-name-customer {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.open-case-lawyer-modal-body-box {
    text-align: center;
    margin-top: 25px;
}

.open-case-lawyer-modal-body-title {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 15px;
}

.open-case-lawyer-modal-body-description {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.open-case-lawyer-modal-documents-box {
    text-align: center;
    margin-top: 25px;
}

.open-case-lawyer-modal-documents-title {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 15px;
}

.open-case-lawyer-modal-documents-image-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.open-case-lawyer-modal-documents-image {
    padding: 5px;
}

.open-case-lawyer-modal-documents-text {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0 auto;
}

/* CUSTOMER */

.open-case-customer-button {
    position: absolute;
    bottom: -2px;
    left: -2px;
    z-index: 10;
}

.preloader-box {
    text-align: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

.preloader-img {
    width: 50px;
}
