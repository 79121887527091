.field-block {
  //border-top: 1px solid #DFDFE0;
  display: flex;
  justify-content: center;
  &__title {
    font-weight: 500;
    font-size: 1.5vw;
    margin: 20px 0;
  }
}

#iframe-pdf {
  // width: 600px;
  // height: 1600px;
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  margin: auto;
}

body > embed {
  top: 90px;
}


.field-iframe {
  height: 300px;
  overflow: auto;
  position: relative;
}

.field-box {
  width: 700px;
  margin: 0px auto 15px;
}

 

 
 