.title {
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    text-transform: uppercase;
    position: absolute;
    top: -150px;
    color: #fff;
}

.alert {
    margin-top: 40px;
}

.alertText {
    background-color: #fff;
}

.paddingBottom {
    padding-bottom: 400px;
}

.preloaderBox {
    text-align: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

.preloaderImg {
    width: 50px;
}